/** @format */
// https://www.makeapie.com/editor.html?c=x8cCUQhaF
// https://www.makeapie.com/explore.html

/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import ReactECharts from "./ReactECharts";

import { selectNodes } from "../features/node/nodeSlice";

import { events, zRenderEvents } from "./tree/radial-tree";

function App() {
  const location = useLocation();
  const nodes = useSelector(selectNodes);

  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  const data = [
    {
      fixed: true,
      x: (window.innerWidth - 100) / 2,
      y: window.innerHeight / 2,
      symbolSize: 100,
      id: "-1",
      name: "JavaScript",
    },
    {
      // symbolSize: 20,
      id: "0",
      name: "Object",
    },
    {
      // symbolSize: 20,
      id: "1",
      name: "String",
    },
    {
      // symbolSize: 20,
      id: "2",
      name: "Number",
    },
    {
      // symbolSize: 20,
      id: "3",
      name: "Boolean",
    },
    {
      // symbolSize: 20,
      id: "4",
      name: "Array",
    },
    {
      // symbolSize: 20,
      id: "5",
      name: "map()",
    },
  ];
  // const data2 = nodes.map(({ id, title }) => ({ id, name: title }));
  let data2 = [];
  for (let i = 0; i < nodes.length; i += 1) {
    const { id, title, isPrimary } = nodes[i];

    if (isPrimary) {
      data2[id] = {
        id,
        name: title,
        fixed: true,
        x: (window.innerWidth - 100) / 2,
        y: window.innerHeight / 2,
        symbolSize: 100,
      };
    } else {
      data2[id] = { id, name: title };
    }
  }
  for (let i = 0; i < data2.length; i += 1) {
    if (!data2[i]) {
      data2[i] = {};
    }
  }

  const edges2 = [];
  for (let i = 0; i < nodes.length; i += 1) {
    const { id: source, childrenNodes = [] } = nodes[i];

    for (let j = 0; j < childrenNodes.length; j += 1) {
      const { id: target } = childrenNodes[j];

      // console.log({ source, target });
      edges2.push({ source, target });
    }
  }

  const edges = [
    {
      source: 0,
      target: 1,
    },
    {
      source: 0,
      target: 2,
    },
    {
      source: 0,
      target: 3,
    },
    {
      source: 0,
      target: 4,
    },
    {
      source: 0,
      target: 5,
    },
    {
      source: 5,
      target: 6,
    },
  ];

  const [name, setName] = useState("JavaScript");
  const [option, setOption] = useState({
    series: [
      {
        type: "graph",
        // id: "",
        // name: "",
        // legendHoverLink: true,
        // coordinateSystem: 'none',
        // xAxisIndex: 0,
        // yAxisIndex: 0,
        // polarIndex: 0,
        // geoIndex: 0,
        // calenderIndex: 0,
        // center: [0, 0],
        // zoom: 1,
        layout: "force",
        // circular: {
        //   rotateLabel: true,
        // },
        force: {
          // initLayout: "circular",
          repulsion: 1000,
          gravity: 0.01,
          // edgeLength: 500,
          // layoutAnimation: true,
          // friction: 0.9,
        },
        roam: true,
        // nodeScaleRatio: 0.6,
        draggable: true,
        // edgeSymbol: ['none', 'none'],
        // edgeSymbolSize: 10,
        cursor: "pointer",
        itemStyle: {
          // color: "red",
          // borderWidth: 0,
          // borderType: 'solid',
          // borderDashOffset: 0,
          // borderCap: "square",
          // borderJoin: "bevel",
          // borderMiterLimit: 10,
          // shadowBlur: 10,
          // shadowColor: "blue",
          // shadowOffsetX: 0,
          // shadowOffsetY: 0,
          // opacity: 1
        },
        lineStyle: {
          color: "black",
          // width: 1,
          // type: "solid",
          // dashOffset: 0,
          // cap: "butt",
          // join: "bevel",
          // miterLimit: 10,
          // shadowBlur: 10,
          // shadowColor: "yello",
          // shadowOffsetX: 0,
          // shadowOffsetY: 0,
          opacity: 0.5,
          curveness: 0,
        },
        symbolSize: 80,
        label: {
          show: true,
          // position: "right",
          // distance: 5,
          // rotate: 0,
          // offset: [30, 40],
          formatter: "{b}",
          // color: "#fff",
          // fontStyle: "normal",
          // fontWeight: "normal",
          // fontFamily: "sans-serif",
          // fontSize: 12,
          // align: "center",
          // verticalAlign: "middle",
          // lineHeight: 56,
          // backgroundColor: "transparent",
          // borderColor: "grey",
          // borderWidth: 0,
          // borderType: "solid",
          // borderDashOffset: 0,
          // borderRadius: 0,
          // padding: 0,
          // shadowColor: "transparent",
          // shadowBlur: 0,
          // shadowOffsetX: 0,
          // shadowOffsetY: 0,
          // width: 200,
          // height: 200,
          // textBorderColor: "red",
          // textBorderWidth: 1,
          // textBorderType: "solid",
          // textBorderDashOffset: 0,
          // textShadowColor: "transparent",
          // textShadowBlur: 0,
          // textShadowOffsetX: 0,
          // textShadowOffsetY: 0,
          // overflow: "none",
          // ellipsis: "truncate",
          // lineOverFlow: "none",
          // rich: {},
        },
        edgeLabel: {
          show: false,
          // position: "middle",
          // formatter: "{b}",
          // color: "orange",
          // fontStyle: "normal",
          // fontWeight: "normal",
          // fontFamily: "sans-serif",
          // fontSize: 12,
          // align: "center",
          // verticalAlign: "middle",
          // lineHeight: 56,
          // backgroundColor: "transparent",
          // borderColor: "green",
          // borderWidth: 1,
          // borderType: "solid",
          // borderDashOffset: 0,
          // borderRadius: 1,
          // padding: 0,
          // shadowColor: "transparent",
          // shadowBlur: 0,
          // shadowOffsetX: 0,
          // shadowOffsetY: 0,
          // width: 100,
          // height: 100,
          // textBorderColor: "red",
          // textBorderWidth: 10,
          // textBorderType: "solid",
          // textBorderDashOffset: 0,
          // textShadowColor: "transparent",
          // textShadowBlur: 0,
          // textShadowOffsetX: 0,
          // textShadowOffsetY: 0,
          // overflow: "none",
          // ellipsis: 'truncate',
          // lineOverFlow: "none",
          // rich: {},
        },
        labelLayout: {
          // hideOverlap: false,
          // moveOverlap: 'shiftX',
          // x: 0,
          // y: 0,
          // dx: 0,
          // dy: 0,
          // rotate: 0,
          // widht: 1,
          // height: 1,
          // verticalAlign: "middle",
          // fontSize: 14,
          // draggable: true,
          // labelLinePoints: [
          //   [0, 0],
          //   [1, 1],
          // ],
        },
        emphasis: {
          // scale: true,
          // focus: "none",
          // blurScope: "coordinateSystem",
          itemStyle: {
            // color: "black",
            // borderColor: "grey",
            // borderWidth: 1,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderCap: "butt",
            // borderJoin: "bevel",
            // borderMiterLimit: 10,
            // shadowBlur: 10,
            // shadowColor: "green",
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // opacity: 1,
          },
          lineStyle: {
            // color: "black",
            // width: 1,
            // type: "solid",
            // dashOffset: 0,
            // cap: "butt",
            // join: "bevel",
            // miterLimit: 10,
            // shadowBlur: 10,
            // shadowColor: "green",
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // opacity: 1,
            // curveness: 0,
          },
          label: {
            // show: true,
            // position: "right",
            // distance: 5,
            // rotate: 0,
            // offset: [30, 40],
            // formatter: "{b}",
            // color: "red",
            // fontStyle: "normal",
            // fontWeight: "normal",
            // fontFamily: "sans-serif",
            // fontSize: 12,
            // align: "center",
            // verticalAlign: "middle",
            // lineHeight: 56,
            // backgroundColor: "transparent",
            // borderColor: "grey",
            // borderWidth: 0,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderRadius: 0,
            // padding: 0,
            // shadowColor: "transparent",
            // shadowBlur: 0,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // width: 200,
            // height: 200,
            // textBorderColor: "red",
            // textBorderWidth: 1,
            // textBorderType: "solid",
            // textBorderDashOffset: 0,
            // textShadowColor: "transparent",
            // textShadowBlur: 0,
            // textShadowOffsetX: 0,
            // textShadowOffsetY: 0,
            // overflow: "none",
            // ellipsis: "truncate",
            // lineOverFlow: "none",
            // rich: {},
          },
          edgeLabel: {
            // show: true,
            // position: "middle",
            // formatter: "{b}",
            // color: "orange",
            // fontStyle: "normal",
            // fontWeight: "normal",
            // fontFamily: "sans-serif",
            // fontSize: 12,
            // align: "center",
            // verticalAlign: "middle",
            // lineHeight: 56,
            // backgroundColor: "transparent",
            // borderColor: "green",
            // borderWidth: 1,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderRadius: 1,
            // padding: 0,
            // shadowColor: "transparent",
            // shadowBlur: 0,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // width: 100,
            // height: 100,
            // textBorderColor: "red",
            // textBorderWidth: 10,
            // textBorderType: "solid",
            // textBorderDashOffset: 0,
            // textShadowColor: "transparent",
            // textShadowBlur: 0,
            // textShadowOffsetX: 0,
            // textShadowOffsetY: 0,
            // overflow: "none",
            // ellipsis: 'truncate',
            // lineOverFlow: "none",
            // rich: {},
          },
        },
        blur: {
          itemStyle: {
            // color: "black",
            // borderColor: "grey",
            // borderWidth: 1,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderCap: "butt",
            // borderJoin: "bevel",
            // borderMiterLimit: 10,
            // shadowBlur: 10,
            // shadowColor: "green",
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // opacity: 1,
          },
          lineStyle: {
            // color: "black",
            // width: 1,
            // type: "solid",
            // dashOffset: 0,
            // cap: "butt",
            // join: "bevel",
            // miterLimit: 10,
            // shadowBlur: 10,
            // shadowColor: "green",
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // opacity: 1,
            // curveness: 0,
          },
          label: {
            // show: true,
            // position: "right",
            // distance: 5,
            // rotate: 0,
            // offset: [30, 40],
            // formatter: "{b}",
            // color: "red",
            // fontStyle: "normal",
            // fontWeight: "normal",
            // fontFamily: "sans-serif",
            // fontSize: 12,
            // align: "center",
            // verticalAlign: "middle",
            // lineHeight: 56,
            // backgroundColor: "transparent",
            // borderColor: "grey",
            // borderWidth: 0,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderRadius: 0,
            // padding: 0,
            // shadowColor: "transparent",
            // shadowBlur: 0,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // width: 200,
            // height: 200,
            // textBorderColor: "red",
            // textBorderWidth: 1,
            // textBorderType: "solid",
            // textBorderDashOffset: 0,
            // textShadowColor: "transparent",
            // textShadowBlur: 0,
            // textShadowOffsetX: 0,
            // textShadowOffsetY: 0,
            // overflow: "none",
            // ellipsis: "truncate",
            // lineOverFlow: "none",
            // rich: {},
          },
          edgeLabel: {
            // show: true,
            // position: "middle",
            // formatter: "{b}",
            // color: "orange",
            // fontStyle: "normal",
            // fontWeight: "normal",
            // fontFamily: "sans-serif",
            // fontSize: 12,
            // align: "center",
            // verticalAlign: "middle",
            // lineHeight: 56,
            // backgroundColor: "transparent",
            // borderColor: "green",
            // borderWidth: 1,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderRadius: 1,
            // padding: 0,
            // shadowColor: "transparent",
            // shadowBlur: 0,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // width: 100,
            // height: 100,
            // textBorderColor: "red",
            // textBorderWidth: 10,
            // textBorderType: "solid",
            // textBorderDashOffset: 0,
            // textShadowColor: "transparent",
            // textShadowBlur: 0,
            // textShadowOffsetX: 0,
            // textShadowOffsetY: 0,
            // overflow: "none",
            // ellipsis: 'truncate',
            // lineOverFlow: "none",
            // rich: {},
          },
        },
        select: {
          itemStyle: {
            // color: "black",
            // borderColor: "grey",
            // borderWidth: 1,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderCap: "butt",
            // borderJoin: "bevel",
            // borderMiterLimit: 10,
            // shadowBlur: 10,
            // shadowColor: "green",
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // opacity: 1,
          },
          lineStyle: {
            // color: "black",
            // width: 1,
            // type: "solid",
            // dashOffset: 0,
            // cap: "butt",
            // join: "bevel",
            // miterLimit: 10,
            // shadowBlur: 10,
            // shadowColor: "green",
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // opacity: 1,
            // curveness: 0,
          },
          label: {
            // show: true,
            // position: "right",
            // distance: 5,
            // rotate: 0,
            // offset: [30, 40],
            // formatter: "{b}",
            // color: "red",
            // fontStyle: "normal",
            // fontWeight: "normal",
            // fontFamily: "sans-serif",
            // fontSize: 12,
            // align: "center",
            // verticalAlign: "middle",
            // lineHeight: 56,
            // backgroundColor: "transparent",
            // borderColor: "grey",
            // borderWidth: 0,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderRadius: 0,
            // padding: 0,
            // shadowColor: "transparent",
            // shadowBlur: 0,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // width: 200,
            // height: 200,
            // textBorderColor: "red",
            // textBorderWidth: 1,
            // textBorderType: "solid",
            // textBorderDashOffset: 0,
            // textShadowColor: "transparent",
            // textShadowBlur: 0,
            // textShadowOffsetX: 0,
            // textShadowOffsetY: 0,
            // overflow: "none",
            // ellipsis: "truncate",
            // lineOverFlow: "none",
            // rich: {},
          },
          edgeLabel: {
            // show: true,
            // position: "middle",
            // formatter: "{b}",
            // color: "orange",
            // fontStyle: "normal",
            // fontWeight: "normal",
            // fontFamily: "sans-serif",
            // fontSize: 12,
            // align: "center",
            // verticalAlign: "middle",
            // lineHeight: 56,
            // backgroundColor: "transparent",
            // borderColor: "green",
            // borderWidth: 1,
            // borderType: "solid",
            // borderDashOffset: 0,
            // borderRadius: 1,
            // padding: 0,
            // shadowColor: "transparent",
            // shadowBlur: 0,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // width: 100,
            // height: 100,
            // textBorderColor: "red",
            // textBorderWidth: 10,
            // textBorderType: "solid",
            // textBorderDashOffset: 0,
            // textShadowColor: "transparent",
            // textShadowBlur: 0,
            // textShadowOffsetX: 0,
            // textShadowOffsetY: 0,
            // overflow: "none",
            // ellipsis: 'truncate',
            // lineOverFlow: "none",
            // rich: {},
          },
        },
        selectedMode: false,
        // categories: [],
        // autoCurveness: false,
        // data: data,
        data: data2.map((val) => {
          if (val.fixed && val.name !== name) {
            val.fixed = false;
          }

          return val;
        }),
        // nodes: [],
        // links: edges,
        // edges: edges,
        edges: edges2,
        // markPoint: {},
        // markLine: {},
        // markArea: {},
        zlevel: 0,
        z: 2,
        left: "center",
        top: "middle",
        right: "auto",
        bottom: "auto",
        width: "auto",
        height: "auto",
        slient: false,
        animation: true,
        // animationThreshold: 200,
        // animationDuration: 1000,
        // animationEasing: "cubicOut",
        // animationDelay: 0,
        // animationDurationUpdate: 300,
        // animationEasingUpdate: "cubicOut",
        // animationDelayUpdate: 0,
        tooltip: {
          // position: [10, 10],
          // formatter: "{b}",
          // backgroundColor: "yellow",
          // borderColor: "red",
          // borderWidth: 0,
          // padding: 5,
          // textStyle: {
          //   color: "#fff",
          //   fontStyle: "normal",
          //   fontWeight: "normal",
          //   fontFamily: "sans-serif",
          //   fontSize: 14,
          //   lineHeight: 56,
          //   width: 100,
          //   height: 100,
          //   textBorderColor: "red",
          //   textBorderWidth: 5,
          //   textBorderType: "solid",
          //   textBorderDashOffset: 0,
          //   textShadowColor: "transparent",
          //   textShadowBlur: 0,
          //   textShadowOffsetX: 0,
          //   textShadowOffsetY: 0,
          //   overflow: "none",
          //   ellipsis: "truncate",
          //   lineOverflow: "none",
          // },
          // extraCssText: "",
        },
      },
    ],
  });

  // useEffect(() => {
  //   const { pathname } = location;
  //   // console.log(pathname.split("/"));
  //   const name = pathname.split("/")[1] || "JavaScript";

  //   setName(name);
  //   const nodeIndex = nodes.findIndex(({ title }) => title === name);

  //   if (nodeIndex < 0) return;

  //   setOption({
  //     ...option,
  //     series: [
  //       {
  //         ...option.series[0],
  //         data: [
  //           {
  //             name,
  //             children: nodes[nodeIndex].childrenNodes.map(({ title }) => ({
  //               name: title,
  //               children: [],
  //             })),
  //           },
  //         ],
  //       },
  //     ],
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname]);

  return (
    <ReactECharts
      option={option}
      events={events}
      zRenderEvents={zRenderEvents}
      style={{
        height: "calc(100vh - 70px)",
        width: "calc(100% - 150px)",
      }}
    />
  );
}

export default App;
