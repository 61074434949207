/** @format */

import flare from "../../data/json/javascript.json";

const option = {
  tooltip: {
    trigger: "item",
    triggerOn: "mousemove",
  },
  series: [
    {
      type: "tree",
      data: [flare],
      // top: "18%",
      // bottom: "14%",
      // layout: "radial",
      // symbol: "emptyCircle",
      // symbolSize: 0,
      initialTreeDepth: 1,
      // animationDurationUpdate: 1000,
      // emphasis: {
      //   focus: "descendant",
      // },

      top: "1%",
      left: "10%",
      bottom: "10%",
      right: "10%",
      symbolSize: 7,
      label: {
        position: "left",
        verticalAlign: "middle",
        align: "right",
        fontSize: 14,
      },
      leaves: {
        label: {
          position: "right",
          verticalAlign: "middle",
          align: "left",
        },
      },
      emphasis: {
        focus: "descendant",
      },
      expandAndCollapse: true,
      animationDuration: 550,
      animationDurationUpdate: 750,
    },
  ],
};

export const events = {
  click: () => {
    console.log("clicked");
  },
};
export const zRenderEvents = {
  click: () => {
    console.log("clicked z");
  },
};

export default option;
