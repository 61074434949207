/** @format */
// https://tailwindcomponents.com/component/tabbar-responsive
// https://tailwindcomponents.com/component/navigation-side-bar

import React from "react";

import { useHistory } from "react-router";
import { useSelector } from "react-redux";

import { selectNodes } from "../features/node/nodeSlice";

const Drawer = () => {
  const history = useHistory();

  const nodes = useSelector(selectNodes);
  const list = nodes.filter(({ isPrimary }) => isPrimary);

  return (
    <div className="fixed lg:visible invisible -translate-x-48 h-full lg:-translate-x-0 duration-300 ease-in-out w-52  rounded-sm bg-white shadow-lg p-3">
      {/* <img
        className="px-5 pt-3 mx-auto mb-10 mt-20 border-none"
        src="https://v1.tailwindcss.com/_next/static/media/tailwindcss-logotype.5d127e1d767d06efa2b03fe4de5f3d84.svg"
        alt={"asdf"}
      /> */}

      <div className="mb-10 mt-20">
        {list.map(({ id, title }) => (
          <div
            className="flex items-center hover:border-r-4 border-green-500 h-10 cursor-pointer hover:border-red-500 p-2 mb-2 hover:bg-gray-100"
            key={id}
            onClick={() => {
              history.push("/" + title);
            }}
          >
            <i className="animate-pulse h-5 w-5 rounded-sm bg-gray-300 mr-2"></i>
            <span className="text-gray-500">{title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Drawer;
