/** @format */

import Header from "./components/Header";
import Drawer from "./components/Drawer";

import styled from "styled-components";

const Body = styled.div`
  padding: 85px 0 0 230px;
`;

function AppLayout({ setIsTree, isTree, children }) {
  return (
    <>
      <Header {...{ setIsTree, isTree }} />
      <Drawer />
      <Body>{children}</Body>
    </>
  );
}

export default AppLayout;
