/** @format */
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import AppLayout from "./AppLayout";

import { getNodesAsync } from "./features/node/nodeSlice";

import Tree from "./components/Tree";
import Graph from "./components/Graph";

function App() {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [isTree, setIsTree] = useState(false);

  useEffect(() => {
    dispatch(
      getNodesAsync({
        // isPrimary: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppLayout {...{ setIsTree, isTree }}>
      {isTree ? <Tree /> : <Graph />}
    </AppLayout>
  );
}

export default App;
