/** @format */
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import ReactECharts from "./ReactECharts";

import { selectNodes } from "../features/node/nodeSlice";

import { events, zRenderEvents } from "./tree/radial-tree";

function App() {
  const location = useLocation();
  const nodes = useSelector(selectNodes);

  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  const [name, setName] = useState("JavaScript");
  const [option, setOption] = useState({
    series: {
      type: "tree",
      // id: '',
      // name: "",
      // zlevel: 0,
      // z: 2,
      top: "1%",
      left: "10%",
      bottom: "10%",
      right: "10%",
      // width: 10,
      // height: 10,
      // layout: "orthogonal",
      // orient: "LR",
      // symbol: "emptyCircle",
      symbolSize: 10,
      // symboleRotate: 1,
      // symbolKeepAspect: false,
      // symboleOffset: [0, 0],
      // edgeShape: "curve",
      // edgeForkPosition: "50%",
      // roam: false,
      expandAndCollapse: true,
      initialTreeDepth: 1,

      itemStyle: {
        // color: "blue",
        // borderColor: "#000000",
        // borderWidth: 50,
        // borderType: "solid",
        // borderDashOffset: 0,
        // borderCap: "butt",
        // borderJoin: "bevel",
        // borderMiterLimit: 10,
        // shadowBlur: 10,
        // shadowColor: "#000000",
        // shadowOffsetX: 0,
        // shadowOffsetY: 0,
        // opacity: 1,
      },

      label: {
        // show: true,
        position: "top",
        // position: "left",
        // position: "inside",
        // position: ["50%", "50%"],
        // distance: 5,
        // rotate: 0,
        // offset: [30,40],
        // formatter: `{b}`,
        // color: "red",
        // fontStyle: "normal",
        // fontWeight: "normal",
        // fontFamily: "sans-serif",
        // fontSize: 14,
        // align: "right",
        // verticalAlign: "middle",
        // borderRadius: 10,
      },
      data: [
        {
          name,
          children: [],
        },
      ],
      leaves: {
        label: {
          // position: ["50%", "50%"],
          // position: "right",
          // position: "topLeft",
          // verticalAlign: "middle",
          // align: "right",
        },
      },
      emphasis: {
        focus: "descendant",
      },
      animationDuration: 550,
      animationDurationUpdate: 750,
    },
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",
    },
  });

  useEffect(() => {
    const { pathname } = location;
    // console.log(pathname.split("/"));
    const name = pathname.split("/")[1] || "JavaScript";

    setName(name);
    const nodeIndex = nodes.findIndex(({ title }) => title === name);

    if (nodeIndex < 0) return;

    setOption({
      ...option,
      series: [
        {
          ...option.series[0],
          data: [
            {
              name,
              children: nodes[nodeIndex].childrenNodes.map(({ title }) => ({
                name: title,
                children: [],
              })),
            },
          ],
        },
      ],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <ReactECharts
      option={option}
      events={events}
      zRenderEvents={zRenderEvents}
      style={{
        height: "calc(100vh - 70px)",
        width: "calc(100% - 150px)",
      }}
    />
  );
}

export default App;
