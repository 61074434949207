/** @format */

import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export async function getNodes({ isPrimary, title } = {}) {
  let query = {};

  if (typeof isPrimary !== "undefined") {
    query = {
      ...query,
      isPrimary,
    };
  }

  if (typeof title !== "undefined") {
    query = {
      ...query,
      title,
    };
  }

  try {
    const { status, data } = await axios.get(`${REACT_APP_API_URL}/nodes`, {
      params: {
        ...query,
        _limit: 500,
      },
    });

    return { status, data };
  } catch (error) {
    console.error(error);

    return { status: 400 };
  }
}

export async function getNode({ id } = {}) {
  try {
    const { status, data } = await axios.get(
      `${REACT_APP_API_URL}/nodes/${id}`
    );

    return { status, data };
  } catch (error) {
    console.error(error);

    return { status: 400 };
  }
}
