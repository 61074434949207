/** @format */

/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import "./Header.style.css";

const Header = ({ setIsTree, isTree }) => {
  return (
    <>
      <div
        className="fixed flex flex-wrap place-items-center z-50"
        // style={{ height: "70px" }}
      >
        <section className="relative mx-auto">
          <nav className="flex justify-between bg-gray-900 text-white w-screen">
            <div className="px-5 xl:px-12 py-6 flex w-full items-center">
              <a className="navbar-burger self-center ml-0 mr-5" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 hover:text-gray-200"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </a>
              <a className="text-3xl font-bold font-heading" href="#">
                {/* <img className="h-9" src="logo.png" alt="logo" /> */}
                Visualise
              </a>
            </div>

            <label
              htmlFor="toogleA"
              className="flex items-center cursor-pointer mx-12"
            >
              <div className="mr-3 text-white-700 font-medium">Graph</div>

              <div className="relative">
                <input
                  id="toogleA"
                  type="checkbox"
                  className="sr-only"
                  checked={isTree}
                  onChange={() => setIsTree(!isTree)}
                />
                <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                <div className="dot absolute w-6 h-6 bg-gray-100 rounded-full shadow -left-1 -top-1 transition"></div>
              </div>

              <div className="ml-3 text-white-700 font-medium">Tree</div>
            </label>
          </nav>
        </section>
      </div>
    </>
  );
};

export default Header;
