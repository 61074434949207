/** @format */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getNodes } from "./nodeAPI";

const initialState = {
  nodes: [],
  status: "idle",
};

export const getNodesAsync = createAsyncThunk(
  "node/fetchNodes",
  async ({ isPrimary, title } = {}) => {
    const response = await getNodes({ isPrimary, title });

    return response.data;
  }
);

export const nodeSlice = createSlice({
  name: "node",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNodesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getNodesAsync.fulfilled, (state, action) => {
        state.status = "idle";

        const tempMap = new Map();

        function createMapOfNodes(map, list = []) {
          list.forEach((node) => {
            if (!map.has(node.id)) {
              map.set(node.id, node);

              createMapOfNodes(map, node.childrenNodes);
            }
          });
        }

        createMapOfNodes(tempMap, state.nodes);
        createMapOfNodes(tempMap, action.payload);

        state.nodes = Array.from(tempMap.values());
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = nodeSlice.actions;

export const selectNodes = (state) => {
  return state.node.nodes;
};

export default nodeSlice.reducer;
